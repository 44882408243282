export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/grapeleaves-logo.png',
    banner_img_root : '/img/grapeleaves-banner.jpg',
    resid : '',
    key_value : 'djwateringholecatering',
    secret_value : 'djwateringholecatering',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24559.20900611492!2d-104.844429!3d39.696927!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c62caa4bbbc2f%3A0x9a60aa5682313052!2s12405%20E%20Mississippi%20Ave%20%23200%2C%20Aurora%2C%20CO%2080012!5e0!3m2!1sen!2sus!4v1665563329882!5m2!1sen!2sus',
    facebook_link:'#',
    RestaurantName:"DJ'S Watering Hole Restaurant"
};
